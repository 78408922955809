<template>
  <div class="xone-container">
    <div class="xone-login">
      <div class="xone-row">
        <label for="user">Usuario</label>
        <div class="xone-input xone-user">
          <input id="user" />
        </div>
      </div>
      <div class="xone-row">
        <label for="pwd">Contraseña</label>
        <div class="xone-input xone-pwd">
          <input id="pwd" type="password" />
        </div>
      </div>
      <div>
        <button>Login</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.xone-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  align-items: center;
}
.xone-login {
  border: 1px;
  display: flex;
  flex-direction: column;
  justify-self: self-start;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12);
  max-width: 1200px;
}

.xone-row {
  padding: 15px 0 0 20px;
  display: flex;
  flex-direction: row;

  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  flex-wrap: wrap;
}

label {
  width: 150px;
  flex-shrink: 0;
  padding: 5px;
  color: gray;
  font-weight: bold;
}

input {
  height: 35px;
  flex-grow: 1;
  flex-shrink: 1;
  border-width: 1px;
  background-color: transparent;
  border-width: 0;
  outline: none;
  font-size: 16px;
}

.xone-input {
  flex-grow: 1;
  display: flex;
  padding: 0 0 0 30px;
  margin: 10px;

  border: 1px solid #86a8c4;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  margin-right: 10px;
}
.xone-user {
  background: url(/assets/user.png) no-repeat 10px 10px;
  background-repeat: no-repeat;
  background-position: left;
  background-size: 20px;
  background-origin: padding-box;
}

.xone-pwd {
  background: url(/assets/pwd.png) no-repeat 7px 7px;
  background-repeat: no-repeat;
  background-position: left;
  background-size: 20px;
  background-origin: padding-box;
}

button {
  align-self: flex-end;
  padding: 10px 20px;
  margin: 10px;
  background-color: transparent;
  color: #86a8c4;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #86a8c4;
  border-radius: 5px;
}
</style>